<template>
  <v-dialog v-model="dialog" max-width="400" scrollable>
    <template v-slot:activator="{on}">
      <v-list-item id="date_p" v-on="on" color="pt-0 pb-0 mt-0 mb-0 pl-0 pa-0 px-0"
                   style="max-width: 170px !important;display: inline-flex !important;">
        <v-list-item-icon class="pr-0 mr-4 pt-0 pb-0 pl-0 ml-0">
          <v-icon class="pt-0 pb-0 pl-0 ml-0" color="primary" x-large>
            mdi-calendar-range
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pl-0 ml-1 pt-0 pb-1">
          <v-list-item-title class="text-muted pt-0 pb-0">
            {{ text }}
          </v-list-item-title>
          <v-list-item-subtitle class="primary--text pt-0 pb-0">
            {{ value }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="pl-0 ml-0 px-0 pb-1">
          <v-icon class="pt-0 pb-0 pl-0 ml-0" color="primary">
            mdi-menu-down
          </v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-card>
      <v-card-text class="pa-0">
        <v-date-picker v-model="value" class="elevation-0" color="primary" width="100%" @change="close"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    date: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: 'Date'
    }
  },
  data: () => {
    return {
      dialog: false
    }
  },
  computed: {
    value: {
      get() {
        return this.date;
      },
      set(val) {
        this.$emit('input', val)
        this.close();
      }
    }
  },
  methods: {
    close() {
      // this.$emit('input', this.value)
      this.dialog = false
    }
  },
  watch: {}
}
</script>

<style>
#date_p {
  padding: 0 !important;
}
</style>
