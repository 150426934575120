<template>
  <v-container fluid>
    <v-card class="" elevation="0">
      <v-card-text class="pa-3">
        <v-row align="center" class="" no-gutters>
          <v-col class="pl-0 text-left" cols="12" md="6" sm="12">
            <date-picker v-bind:date="this.start" v-model="start" class="mr-6" :text="$t('generic.lang_from')"/>
            <date-picker v-bind:date="this.end" class="ml-4" v-model="end" :text="$t('generic.lang_to')"/>
          </v-col>

          <v-col class="text-right" cols="12" md="2" sm="12">
            <b-form-input v-model="search" class="ma-auto" :placeholder="$t('generic.lang_search')" size="md"
                          style="height: 44px !important;"/>
          </v-col>
          <v-col class="border-right" cols="4" md="2" sm="4">
            <v-btn :loading="downloadingPdfLoader" :disabled="downloadingPdfLoader" @click="downloadPDF" color="primary"
                   icon>
              <v-icon class="f-size-52" x-large>
                mdi-file-pdf-box
              </v-icon>
            </v-btn>
            <!--            <v-btn color="success" icon>
                          <v-icon class="f-size-52" x-large>
                            mdi-file-excel-box
                          </v-icon>
                        </v-btn>-->
          </v-col>

          <v-col class="text-right d-flex" md="2">

            <div class="mr-2">
              <v-icon

                  color="primary"
                  large
              >
                mdi-wallet
              </v-icon>
              {{ this.user.net_credit | currency }}
            </div>
            <v-btn color="primary" elevation="0" class="pl-2" @click="showRefillDialog">
              {{ $t('generic.lang_chargeAccount') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pa-0">
        <transaction-data-table v-bind:search="search" :transactions="transactions"/>
      </v-card-text>
    </v-card>

    <v-dialog max-width="700" scrollable v-model="dialog">
      <v-card>

        <v-card-title class="pa-0 card-header">
          <v-tabs v-model="tabs" color="primary">
            <v-tab>Paypal</v-tab>
            <v-tab>Visa / Master card</v-tab>
          </v-tabs>
        </v-card-title>
        <v-card-text>

          <v-tabs-items v-model="tabs" class="mt-4">
            <v-tab-item>
              <v-row no-gutters>
                <v-col cols="12" class="pt-3">
                  <v-text-field :min="5" v-model="balance" outlined dense type="number" label="Bitte Betrag eingeben"/>
                </v-col>

                <v-col cols="12">
                  <v-btn :disabled="loading" :loading="loading" @click="getUrl" color="primary" block class="mx-auto">
                    Aufladen
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="text-center">
              <p class="text-muted">Unbekannter Fehler...</p>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DatePicker from '@/components/dashboard/orders/overview/DatePicker'
import TransactionDataTable from '@/components/dashboard/payout/TransactionDataTable'
import ENDPOINTS from "@/plugins/axios/endpoints";
import {createNamespacedHelpers} from "vuex";
import FileSaver from 'file-saver'


export default {
  name: 'index',
  components: {
    TransactionDataTable,
    DatePicker,
  },
  async beforeRouteEnter(to, from, next) {
    //set up dates
    let start = new Date().getTime();
    let end = (new Date().getTime() + (24 * 60 * 60 * 30));
    const transactions = await window.axios.get(ENDPOINTS.CANTEEN.PAYMENT.TRANSACTION.GET_ALL, {
      params: {
        start: start,
        end: end,
        "filter[status]": true
      }
    }).then((res) => res.data.data);

    next(vm => vm.transactions = transactions);
  },
  layout: 'dashboard',
  computed: {
    ...createNamespacedHelpers('auth').mapGetters({
      user: 'getUser'
    }),
  },
  data: () => {
    return {
      balance: 5,
      tabs: 0,
      dialog: false,
      transactions: [],
      loading: false,
      search: "",
      start: "",
      end: "",
      downloadingPdfLoader: false
    }
  },
  watch: {
    '$route.params.status': function (status) {
      if (status === 'success')
        this.$store.commit('alert/show', {
          message: 'Error',
          color: 'error',
          visible: true
        })
      else if (status === 'canceled')
        this.$store.commit('alert/show', {
          message: 'Error',
          color: 'error',
          visible: true
        })
    },
    start() {
      this.reloadTransactions()
    },
    end() {
      this.reloadTransactions()
    }
  },
  created() {

    /**
     * show payment messages based on 'status' param value
     * */
    if (this.$route.params.status === 'success') {
      this.$store.commit('alert/show', {
        message: 'Guthaben wurde erfolgreich aufgeladen',
        color: 'success',
        visible: true
      })

      this.$router.replace('/payout')
    } else if (this.$route.params.status === 'canceled') {
      this.$store.commit('alert/show', {
        message: 'Zahlungsvorgang wurde abgebrochen',
        color: 'warning',
        visible: true
      })
      this.$router.replace('/payout')
    }

    /**
     * set dates
     * */
    this.start = new Date().toISOString().substr(0, 10);
    const tmp = new Date();
    tmp.setDate(tmp.getDate() + 30)
    this.end = tmp.toISOString().substr(0, 10);

  },
  methods: {
    /**
     * download transactions history
     * */
    downloadPDF() {
      this.downloadingPdfLoader = true;
      this.$axios.get(ENDPOINTS.CANTEEN.PAYMENT.TRANSACTION.DOWNLOAD, {
            responseType: 'arraybuffer',
            params: {
              start: Math.round(new Date(this.start).getTime() / 1000),
              finished: Math.round(new Date(this.end).getTime() / 1000),
            }

          }
      ).then((res) => {
        //const blobContent = new Blob([res.data], {type: 'pdf'});
        FileSaver.saveAs(new Blob([res.data], {
          type: "pdf"
        }), "lunchBee-transactions.pdf");
      }).catch((err) => {
        this.$store.commit('alert/show', {
          message: err.message,
          color: 'error',
          visible: true
        })
      }).finally(() => {
        this.downloadingPdfLoader = false;
      })
    },
    /**
     * reload transactions after changing date
     */
    async reloadTransactions() {
      this.transactions = await window.axios.get(ENDPOINTS.CANTEEN.PAYMENT.TRANSACTION.GET_ALL, {
        params: {
          start: Math.round(new Date(this.start).getTime() / 1000),
          end: Math.round(new Date(this.end).getTime() / 1000),
          "filter[status]": true
        }
      }).then((res) => res.data.data);
    }
    ,
    showRefillDialog() {
      this.dialog = true
    }
    ,
    getUrl() {
      this.loading = true;
      this.$axios.post(ENDPOINTS.CANTEEN.PAYMENT.CHARGE, {
        balance: parseFloat(this.balance)
      }).then((res) => {
        let a = document.createElement('a');
        a.href = res.data.url;
        a.click();
        a.remove();
      }).catch(() => {
        this.$store.commit('alert/show', {
          message: 'Error',
          color: 'error',
          visible: true
        })
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
