<template>
  <v-data-table :search="search" v-if="transactions" :headers="this.headers" :items="this.transactions"
                :items-per-page="20">
    <template v-slot:item.id="{item}">
      <strong class="primary--text">
        #{{ item.id }}
      </strong>
    </template>


    <template v-slot:item.finished_at="{item}">
      {{ $moment(item.finished_at * 1000).format("YYYY.MM.DD HH:MM") }}
    </template>

    <template v-slot:item.payload.value="{item}">
      <strong class="primary--text">
        {{ item.payload.value | currency }}
      </strong>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'TransactionDataTable',
  ssr: false,
  props: {
    transactions: Array,
    search: String
  },
  data() {
    return {
      headers: [
        {
          text: "ID",
          value: 'id'
        },
        {
          text: this.$t('generic.lang_transactionDate'),
          value: 'finished_at'
        },
        {
          text: this.$t('generic.lang_chargeValue'),
          value: 'payload.value'
        }
      ]
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>
